import React, { Component } from "react";
import styled from "styled-components";
import { Section } from "../Theme";
import { Link } from "gatsby"
import media from "styled-media-query";

const SearchMainContainer = styled.div`
  background-color: #f1f1f1;
  overflow: hidden;
`;

const SearchSection = styled(Section)` 
    
    ${media.lessThan("large")`
        display: flex;

        input {
          font-size: 18px;
          height: 40px;
          line-height: 40px;
        }
    `}

    .searchinput__button {
      ${media.lessThan("medium")`
          span {
            display: none;
          }

          .image-icon {
            margin: auto;
          }
      `}
      ${media.greaterThan("medium")`
          min-width: 160px;
      `}
    }

    input {
      width: 100%;
      border: 0px;
      background-color: #f1f1f1;
      background-repeat: no-repeat;
      background-position: 1.5rem center;
      height: 63px;
      font-size: 20px;
      padding: 1rem 2rem 1rem 3.5rem;
      grid-column: 1/13;
    }

    ${media.lessThan("large")`
      input {
        grid-column: 1/10;
      }

      a {
        display: block;
        grid-column: 10/13;
        button {
          font-size: 24px;
          width: 100%;
        }
      }
    `}

    .ais-Hits {
      ul {
        margin: 0;
        padding: 0;

        li {
          padding: 0;
          margin: 0;
        }
      }
    }
`;


// import media from "styled-media-query";
// import HitComponent from './HitComponent'
// import searchClient from './client'

// const SearchSection = styled.div `

// `
// const SearchHeader = styled.h3`
//   font-size: 64px;
//   display: inline;
//   ${media.lessThan("large")`
//         font-size: 36px;
//         line-height: 30px;
//         height: 30px;
//         transform: translateY(-20px);
//         margin: 0;
//         padding: 0;
// `}
// `;
// const SearchContainer = styled.div `
//     display: block;
//     max-width: 1640px;
//         width: 100%;
//     margin-top: 0px;
//     margin-right: auto;
//     margin-left: auto;
//     padding-right: 50px;
//     padding-left: 50px;
//     padding-top: 60px;
// `

// // const HitGrid = styled.section `
// //     display: grid;
// //     max-width: 1640px;
// //         width: 100%;
// //     margin: 0px auto 40px auto;
// //     grid-column-gap: 50px;
// //     grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
// //     align-items: start;

// //     h3 {
// //         font-family: nhp;
// //         text-transform: none;
// //         grid-column: 2/12;
// //         margin: 0;
// //         padding: 10px 0;
// //         border-bottom: 2px solid #000;
// //         color: #000;
// //         span {
// //             font-size: 14px;
// //             opacity: .5;
// //             margin-left: 20px;
// //         }
// //     }

// //     a {
// //         opacity: .5;
// //         float: right;
// //         font-size: 14px;
// //     }
// // `

class AlgoliaSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};
    this.handleChange = this.handleChange.bind(this);
  }

//   componentWillMount(){
//     const { indexes } = this.props
//     this.indexes = indexes
//       .map(i => searchClient.initIndex(i.name))
//   }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  render() {
    // const { query } = this.state

    return (
      <>
        <form>
          <SearchSection xsmall>
            <input
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
              placeholder={this.props.placeholder}
              className="searchinput"
              style={{backgroundImage: "url(" + require("../../images/icons/icon__search.svg") + ")"}}
            />
            <Link to={`/digital-index?query=${this.state.value}&page=1&configure%5BhitsPerPage%5D=30`}>
              <button className="searchinput__button">
              <span>Search</span>
              <img
                  src={require("../../images/icons/icon__chevron--white--right.svg")}
                  alt="daily overview"
                  className="image-icon"
              />
              </button>
            </Link>
          </SearchSection>
        </form>
      </>
    )
  }
}

export default AlgoliaSearch
