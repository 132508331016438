import React, { Component } from "react";
import styled from "styled-components";
import Text, { Section } from "../components/Theme";
import HomeSearch from "../components/AlgoliaSearch/HomeSearch";
// import { NewsletterSignup } from "../components/newsletter.js";
// import NewsletterSignup from "../components/newsletter.js";
import chevronRight from '../images/icons/icon__chevron--white--right.svg';
import Carousel from "nuka-carousel";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";

import media from "styled-media-query";
// import {
//     // InstantSearch,
//     // Hits,
//     // SearchBox,
//     Highlight,
//     // Configure
// } from "react-instantsearch-dom";
import Header from "../components/Header";
import Info from "../components/info-icon.js";
import Footer from "../components/footer.js";

const SectionGrey = styled.div`
    background-color: var(--off-white);
`;

const ShopButtons = styled(Section)`
    padding: 0;
    min-width: 250px;

    a {
        display: block;
        min-width: 200px;
    }

    .shopButton {
        background-color: var(--black);
        width: 100%;
        padding: 0;

        button {
            border: none;
            padding: 0.5rem 1rem;
            text-transform: uppercase;
            color: var(--white);
            background-color: var(--black);
            letter-spacing: 0.125rem;
        }
    }
`;

const CTAContainer = styled.div`
    grid-column: 1/13;
    display: grid;
    padding: 40px 0 0 0;
    // justify-content: start;
    // justify-items: start;
    // align-items: start;
    // align-content: start;
    // grid-auto-flow: column;
    // grid-auto-columns: minmax(1px, 1fr);
    // grid-template-columns: 1fr 1fr;
    // grid-template-rows: 1fr;
    // grid-column-gap: 50px;
    // grid-row-gap: 0px;

    ${media.greaterThan("medium")`
        margin-left: -2rem;
        margin-right: -2rem;
    `}
`;

const NewsletterContainer = styled.div`
    grid-column: 1/12;
    padding: 0;
    max-width: 1640px;
    margin: auto;

    h3 {
        margin-top: 0;
    }
`;

const SubscribeBox = styled.div`
    // grid-column: 2/11;
    // padding: 50px 0 0 0;

    input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0;
        width: 100%;
        border: 0px;
        background-color: #f1f1f1;
        background-repeat: no-repeat;
        background-position: 1.5rem center;
        height: 63px;
        font-size: 20px;
        padding: 1rem 2rem;
        grid-column: 1/13;

        &::placeholder {
            opacity: 0.3;
        }

        &[type="submit"] {
            border: none;
            background-size: 14px;
            background-position: calc(100% - 1rem) center;
            background-repeat: no-repeat;
            background-image: url(${chevronRight});
            background-color: var(--black);
            color: var(--white);
            width: 100%;
            min-width: 100px;
            max-width: 180px;
            text-align: left;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            height: 63px;
            font-size: 20px;
            line-height: 1;
        }
    }
`;

const NewsletterTitle = styled(Text)`
    margin-bottom: 20px;
    color: var(--black);
    font-family: var(--nhp);
    font-weight: 600;
    line-height: 1.2;
    width: 100%;

    ${media.lessThan("medium")`
        width: 100%;
        font-size: 1.4rem;
    `}    

    a {
        display: block;
        font-size: 11px;
        padding-top: 20px;
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }
`;

const SectionTitle = styled(Text)`

`;

const NewsletterParagraph = styled.p`
    color: var(--grey);
    font-family: var(--nhp);
    font-size: 18px;
    line-height: 1.2;
    margin: 20px 0;
`;

const ProjectsParagraph = styled.p`
    color: var(--grey);
    font-family: var(--nhp);
    font-size: 24px;
    line-height: 1.2;
    margin: 20px 0;
    margin-bottom: 2rem;

    ${media.lessThan("large")`
        font-size: 18px;
    `}    
`;

const SocialContainer = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ShopText = styled.div`
    grid-column: 4/13;
`;

const ProjectColumn = styled.div`
    grid-column: 1/4;
`;

const ProjectColumnImages = styled.div`
    grid-column: 5/13;
`;

const CarouselFrame = styled(Carousel)`
    width: 100%;
    background-color: var(--white);

    li {
        cursor: default;
    }

    .slider-frame {
        width: 100%;
    }
`;

const ShopCarousel = styled(Carousel)`
    width: 100%;
    grid-column: 1/13;
    grid-row: 3/4;
    align-items: start;
    justify-content: start;
    align-self: start;
    margin-left: -20px;
    margin-right: -20px;

    li {
        cursor: default;
    }

    .slider-frame {
        width: 100%;
    }

    .slider-slide {
        padding: 0 20px;
    }

    button {
        background: none;
    }

    ${media.greaterThan("large")`
        .slider-control-centerleft {
            left: -40px !important;
        }

        .slider-control-centerright {
            right: -40px !important;
        }
    `}

    ${media.lessThan("large")`
        margin: auto;
    `}
`;

const IndexPageHeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
`

const HeroContainer = styled.div`
    background-color: #000;
    width: 100%;
    height: 100vh;

    .slider-frame {
        width: 100%;
    }

    .slider-slide {
        background-color: var(--white);
        overflow: hidden;
    }

    .intro-section-gradient {
        height: 100%;
        width: 100%;
        position: absolute;
        // z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgb(0,0,0);
        background: radial-gradient(circle, rgba(0,0,0,0) 53%, rgba(0,0,0,0.5) 100%);
    }

    .mobile-padding__bottom {
        ${media.lessThan("large")`
            padding-bottom: 60px;
        `}
    }
`;

const HeroGrid = styled.div`
    .hero-inner {
        display: flex;
        min-height: 100vh;
        z-index: 9998;
        position: relative;
        align-items: flex-end;
        color: var(--white);

        ${media.lessThan("large")`
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: calc(100% - 120px);
        `}

        ${media.lessThan("medium")`
            width: calc(100% - 60px);
        `}
    }

    ${media.lessThan("large")`
            display: grid;
            padding-left: 0 !important;
            align-items: center;
            padding-right: 0 !important;
    `}

    .gatsby-image-wrapper {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: -1;
        grid-column: 1/13;
        grid-row: 1;
        width: 100%;
        height: 100vh;
    }

    .section-button {
        margin-bottom: 40px;
    }

    .hero-inner__title {
        line-height: 1.15;
    }

    .hero-inner__paragraph {
        margin-bottom: 30px;
        font-size: 1.3rem;
        max-width: 550px;
    }
`;

const FeaturedOverviewContainer = styled(Section)`
    grid-row-gap: 20px;
`;

const ShopContainer = styled(Section)`
    padding-bottom: 0;
    align-items: flex-end;
`;

const FeaturedOverview = styled.div`
    grid-column: 1/6;
    grid-row: 3/4;
    align-items: start;
    justify-content: start;
    align-self: start;

    h3 {
        opacity: .4;
        padding-top: 10px;
    }
`;

const FeaturedDesc = styled(Text)`
    grid-column: 6/14;
    grid-row: 3/4;
    padding-top: 16px;
    margin-bottom: 10px;
    color: var(--grey);
    font-family: var(--nhp);
    line-height: 1.3;

    a {
        display: block;
        font-size: 11px;
        padding-top: 20px; 
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }  
`;

const Circle = styled.div`
    grid-column: 1/4;
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 1000px;
    position: relative;

    &:hover,
    &:focus {
        InnerCircle {
            opacity: 0;
        }
    }

    ${media.lessThan("large")`
        margin-bottom: 40px;
    `}
`;

const InnerCircle = styled.div`
    background-color: white;
    width: 70%;
    height: 70%;
    border-radius: 1000px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const SearchButtons = styled(Section)`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;

  .searchButtonContainer {
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px;
  }

  .searchButton {
    background-color: #fff;
    display: block;
    padding: 0 5px;

    button {
      border: 1px solid;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      background-color: #fff;
      letter-spacing: 0.125rem;
      white-space: nowrap;
      width: 100%;

      ${media.lessThan("large")`
        margin-bottom: 10px;
      `}
    }
  }
`;

const NewsletterWrapper = styled(Section)`
    display: block;

    .grid-padding-x {
        > .cell {
            // padding-left: 3rem;
            padding-right: 3rem;

            ${media.greaterThan("large")`
                padding-left: 3rem !important;
            `}

            ${media.lessThan("1715px")`
                padding-left: 0 !important;
            `}            

            &:last-child {
                padding-left: 2rem !important;

                ${media.lessThan("medium")`
                    padding-left: 0;
                    padding-right: 0;
                    margin-top: 2rem;
                `}                  
            }
        }
    }
`;

const PageIntroText = styled(Text)`  
  ${media.lessThan("large")`
    font-size: 18px;
    line-height: 24px;
  `}
`;

class IndexPage extends Component {
    state = {
        contentColor: "#ffffff",
        markers: [],
        center: { lat: 37.759703, lng: -122.428093 },
        pageWidth: 1,
    };

    componentWillMount() {
        this.slides = this.props.data.allDatoCmsHome.edges[0].node.heroSlider;
        this.setState({ contentColor: this.slides[0].color });

        const markers = (this.nodes = this.props.data.allDatoCmsOverview.edges.map(
            e => e.node
        ));
        this.setState({ markers });

    }

    componentDidMount() {
        this.measure();
        window.addEventListener('resize', this.measure.bind(this));
    }

    componentWillUnmount() {
        this.measure();
        window.addEventListener('resize', this.measure.bind(this));
    }

    measure() {
        this.setState({ pageWidth: window.outerWidth });
    }

    sliderAfterChange = current => {
        const contentColor = this.slides[current].color;
        this.setState({ contentColor });
    };

    matchColors(color) {
        if (color == 'black') {
            color = '#303235'
        }
        return color;
    }

    carouselAnimation(data) {
        return (data.heroSlider.length > 1) ? "slide" : "fade";
    }

    render() {
        const { data } = this.props;
        const { contentColor } = this.state.contentColor;
        // const { contentColor, markers, center } = this.state;
        const { node: pageData } = data.allDatoCmsHome.edges[0];

        return (
            <Layout>
                <div className="page-home" ref={div => (this.wrapper = div)}>
                    <IndexPageHeaderContainer>
                      <Header content_color={contentColor} />
                    </IndexPageHeaderContainer>
                    <HeroContainer>
                        <CarouselFrame
                            width="100%"
                            autoplayInterval={3000}
                            pauseOnHover
                            wrapAround
                            slideWidth={1.0}
                            initialSlidewidth={1.0}
                            // autoplay
                            renderCenterLeftControls={null}
                            renderCenterRightControls={null}
                        >
                        {pageData.heroSlider.map((block, index) => (
                            <div key={`slide-${index}`}>
                                {/*{block.overview && (
                                <Info color={block.color} />
                                <div className="info-container">
                                    <h4 className="body-copy-medium">
                                        {block.overview.title}
                                    </h4>
                                    <div className="body-copy-small">
                                        {block.overview.description}
                                    </div>
                                    <p
                                        className={`caption toast-geo ${block.color}`}
                                    >
                                        {block.overview.geo.latitude.toFixed(
                                            6
                                        )}
                                        °,{" "}
                                        {block.overview.geo.longitude.toFixed(
                                            6
                                        )}
                                        °
                                    </p>
                                </div>
                                )}*/}
                                <HeroGrid>
                                    <div className="hero-inner grid-container">
                                        <div className="grid-x grid-padding-y">
                                            <div className="cell small-12 mobile-padding__bottom">
                                                {block.title && (
                                                <Text className="body-copy-medium hero-inner__title" style={{color: this.matchColors(block.color)}}>
                                                    {block.title}
                                                </Text>

                                                )}
                                                {block.text && (
                                                <div
                                                    className="hero-inner__paragraph"
                                                    style={{color: this.matchColors(block.color)}}
                                                    dangerouslySetInnerHTML={{
                                                        __html: block.text
                                                    }}
                                                />
                                                )}
                                                {block.buttonLink && block.buttonText && (
                                                <Link to={block.buttonLink}>
                                                    {block.color === 'black' ? (
                                                        <button className="section-button">
                                                            <span>{block.buttonText}</span>
                                                            <img
                                                                src={require("../images/icons/icon__chevron--white--right.svg")}
                                                                alt="daily overview"
                                                                className="image-icon"
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button className="section-button section-button__white">
                                                            <span>{block.buttonText}</span>
                                                            <img
                                                                src={require("../images/icons/icon__chevron--right.svg")}
                                                                alt="daily overview"
                                                                className="image-icon"
                                                            />
                                                        </button>
                                                    )}
                                                </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="intro-section-gradient"></div>
                                    <Img
                                        fluid={this.state.pageWidth < 629 ? block.mobileImage.fluid : block.image.fluid}
                                        loading="eager"
                                        alt={block.title}
                                        backgroundColor="#fff"
                                        placeholderStyle={{
                                            filter: "blur(10px)"
                                        }}
                                        fadeIn={false}
                                    />
                                </HeroGrid>
                            </div>
                        ))}
                        </CarouselFrame>
                    </HeroContainer>

                    <Section large className="intro-text-section">
                        <Circle
                            className="circle-image"
                            col="2/12"
                            style={{
                                backgroundImage: `url(${(pageData.circularImage.fluid.src) ? pageData.circularImage.fluid.src : 'https://www.datocms-assets.com/12893/1635874687-8d8e695a-39ca-4dbc-98b9-77e850d0b814.jpeg?auto=compress%2Cformat&dpr=1&fit=clip&w=900'})`
                            }}
                        >
                            <InnerCircle />
                        </Circle>
                        <PageIntroText
                            sub
                            col="5/13"
                            mobileCol="1/13"
                            dangerouslySetInnerHTML={{
                                __html: pageData.introText
                            }}
                        />
                    </Section>

                    <Section small className="intro-text-section no-padding-bottom no-padding-top">
                        <SectionTitle
                            sub
                            col="1/13"
                            mobileCol="1/13"
                        >
                            <img
                                src={require("../images/icons/icon__calendar.svg")}
                                alt="daily overview"
                                className="image-icon__large"
                            />
                            {pageData.featuredTitle && (
                            <span className="section-title">{pageData.featuredTitle}</span>
                            )}
                            {pageData.featuredLeadParagraph && (
                            <p className="section-lead-paragraph">{pageData.featuredLeadParagraph}</p>
                            )}
                        </SectionTitle>
                    </Section>

                    {data.allDatoCmsOverview.edges.map(({ node: overview }) => (
                        <FeaturedOverviewContainer
                            stacked
                            small
                            key={overview.id}
                        >
                            <div
                                id="w-node-bdf8efa2c87b-d6aaf2c3"
                                className="featured-img"
                            >
                                <Link to="/daily">
                                    <Img
                                        fluid={overview.image.fluid}
                                        alt={overview.title}
                                        style={{ maxHeight: "350px" }}
                                    />
                                </Link>
                            </div>
                            <FeaturedOverview>
                                <h2 className="subheader featured-overview">
                                    <Link to="/daily">{overview.title}</Link>
                                </h2>
                                <Text caption>
                                    {overview.geo.latitude.toFixed(6)}°,{" "}
                                    {overview.geo.longitude.toFixed(6)}°
                                </Text>
                            </FeaturedOverview>
                            <FeaturedDesc small>
                                {overview.description}
                                <Text caption><a href="https://www.instagram.com/dailyoverview/" target="_blank" rel="noopener noreferrer">View on Instagram &rarr;</a></Text>
                            </FeaturedDesc>
                        </FeaturedOverviewContainer>
                    ))}

                    <HomeSearch placeholder="Search the Overview Index" />

                    <SearchButtons>
                        <div className="grid-x align-space-between searchButtonContainer">
                            {pageData.featuredCategories.map(featured_cat => (
                                <div className="cell small-6 medium-4 large-2" key={featured_cat.name}>
                                  <Link className="searchButton" to={featured_cat.url}>
                                    <button>{featured_cat.name}</button>
                                  </Link>
                                </div>
                            ))}
                        </div>
                    </SearchButtons>

                    <SectionGrey>
                        <ShopContainer small className="shop-slider__intro">
                            <Text
                                sub
                                col="1/4"
                                mobileCol="1/13"
                            >
                                <img
                                    src={require("../images/icons/icon__cart.svg")}
                                    alt="daily overview"
                                    className="image-icon__large"
                                />
                                <span className="section-title">{(pageData.shopSliderTitle) ? pageData.shopSliderTitle : 'Shop'}</span>
                            </Text>
                            <ShopText
                                col="4/13"
                                mobileCol="1/13"
                            >
                                <p className="section-lead-paragraph">{(pageData.shopSliderLeadParagraph) ? pageData.shopSliderLeadParagraph : 'Through our daily posts, products, and collaborations we aim to inspire the Overview Effect.'}</p>
                            </ShopText>
                        </ShopContainer>

                        <Section small className="shop-slider">
                            <ShopCarousel
                                width="100%"
                                transitionMode="scroll"
                                autoplayInterval={3000}
                                pauseOnHover
                                wrapAround
                                renderBottomCenterControls={false}
                                slidesToScroll={1}
                                slidesToShow={this.state.pageWidth < 629 ? 1 : 4}
                                slideWidth={1.0}
                                initialSlidewidth={1.0}
                                autoplay
                                renderCenterLeftControls={({ previousSlide }) => (
                                    <button onClick={previousSlide}>
                                        <img
                                            src={require("../images/icons/icon__chevron--left.svg")}
                                            alt="daily overview"
                                            className="image-icon__large"
                                        />
                                    </button>
                                )}
                                renderCenterRightControls={({ nextSlide }) => (
                                    <button onClick={nextSlide}>
                                        <img
                                            src={require("../images/icons/icon__chevron--right.svg")}
                                            alt="daily overview"
                                            className="image-icon__large"
                                        />
                                    </button>
                                )}
                            >
                                {pageData.shopSliderProducts.map((shop_slide, i) => (
                                    <Link to={shop_slide.overview.shopLink} key={`slide-${i}`}>
                                        <div className="shop-slider__slide">
                                            <div className="onebytwo">
                                                <img alt="Daily Overview" src={shop_slide.overview.image.fluid.src} />
                                            </div>
                                            <h4>{shop_slide.overview.title}</h4>
                                        </div>
                                    </Link>
                                ))}
                            </ShopCarousel>
                        </Section>

                        <Section small className="shop-slider__buttons">
                            <ShopButtons>
                                <Link to={(pageData.shopSliderButtonLink) ? pageData.shopSliderButtonLink : '/digital-index?query=${this.state.value}&page=1&configure%5BhitsPerPage%5D=30'}>
                                  <button className="searchinput__button" style={{minWidth:'210px'}}>
                                      <span>{(pageData.shopSliderButtonText) ? pageData.shopSliderButtonText : 'Shop Prints'}</span>
                                      <img
                                          src={require("../images/icons/icon__chevron--white--right.svg")}
                                          alt="daily overview"
                                          className="image-icon"
                                      />
                                  </button>
                                </Link>
                            </ShopButtons>
                        </Section>

                        <Section small className="featured-cta">
                            {/* TODO: MAKE LINKS MODULAR */}
                            <CTAContainer>
                                <div className="grid-x grid-padding-y grid-padding-x">
                                    <div className="cell small-12 medium-6">
                                        <Link to={'/custom'}>
                                            <img
                                                src={(pageData.customPrintsImage) ? pageData.customPrintsImage.fluid.src : require("../images/custom-print-photo-1.jpg")}
                                                alt="daily overview"
                                                className=""
                                            />
                                            <h3>{(pageData.customPrintsTitle) ? pageData.customPrintsTitle : 'Generate Your Own Custom Print'}</h3>
                                            <p>{(pageData.customPrintParagraph) ? pageData.customPrintParagraph : 'How water shapes urban planning in the "Sunshine State"'}</p>
                                        </Link>
                                    </div>
                                    <div className="cell small-12 medium-6">
                                        <Link to={'/timelapse'}>
                                            <img
                                                src={(pageData.bookImage) ? pageData.bookImage.fluid.src : 'https://www.datocms-assets.com/12893/1590955628-cleanshot-2020-05-25-at-23-25-46-2x.jpeg?auto=compress%2Cformat&dpr=1&fit=clip&w=900'}
                                                alt="daily overview"
                                                className=""
                                            />
                                            <h3>{(pageData.bookTitle) ? pageData.bookTitle : 'Overview Timelapse: How We Change The Earth'}</h3>
                                            <p>{(pageData.bookParagraph) ? pageData.bookParagraph : 'A photographic survey of the state of change on Earth today.'}</p>
                                        </Link>
                                    </div>
                                </div>
                            </CTAContainer>
                        </Section>
                    </SectionGrey>

                    {/*<NewsletterSignup />*/}
                    <NewsletterWrapper small className="subscribe-section">
                        <NewsletterContainer>
                            <div className="grid-x grid-padding-x">
                                <div className="cell small-12 medium-6 large-5">
                                    <SubscribeBox>
                                        <NewsletterTitle sub>
                                            {(pageData.ctaSection.sectionTitle) ? pageData.ctaSection.sectionTitle : 'To receive our daily post and the occasional announcement:'}
                                        </NewsletterTitle>
                                        <form
                                            action="https://www.getrevue.co/profile/dailyoverview/add_subscriber"
                                            method="post"
                                            id="revue-form"
                                            name="revue-form"
                                            target="_blank"
                                            className="subscribe-section__form"
                                        >
                                            <input
                                                className="revue-form-field"
                                                placeholder={(pageData.ctaSection.emailPlaceholder) ? pageData.ctaSection.emailPlaceholder : 'Email'}
                                                type="email"
                                                name="member[email]"
                                                id="member_email"
                                            />
                                            <input
                                                type="submit"
                                                value={(pageData.ctaSection.emailSubmitButton) ? pageData.ctaSection.emailSubmitButton : 'Submit'}
                                                name="member[subscribe]"
                                                id="member_submit"
                                                className="revue-form-submit"
                                            />
                                        </form>
                                        {/*{pageData.ctaSection.newsletterInstructions && (
                                        {<NewsletterParagraph>
                                            {pageData.ctaSection.newsletterInstructions}
                                        </NewsletterParagraph>}
                                        )}*/}
                                    </SubscribeBox>
                                </div>
                                <div className="cell small-12 medium-6 large-4 large-offset-1">
                                    <h3>{(pageData.ctaSection.socialTitle) ? pageData.ctaSection.socialTitle : 'Follow Overview on Social'}</h3>
                                    {/*{pageData.ctaSection.socialLeadParagraph && (
                                    <NewsletterParagraph>
                                        {pageData.ctaSection.socialLeadParagraph}
                                    </NewsletterParagraph>
                                    )}*/}
                                    <SocialContainer>
                                        <a className="newsletter-social newsletter-social__facebook"
                                           href="https://www.facebook.com/doverview"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           key="SocialContainer1"
                                        >
                                            <img
                                                src={require("../images/icons/icon__facebook.svg")}
                                                width="24"
                                                alt="Daily Overview"
                                                className="grid-right"
                                            />
                                        </a>
                                        <a className="newsletter-social newsletter-social__twitter"
                                           href="https://twitter.com/DOverview"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           key="SocialContainer2"
                                        >
                                            <img
                                                src={require("../images/icons/icon__twitter.svg")}
                                                width="24"
                                                alt="Daily Overview"
                                                className="grid-right"
                                            />
                                        </a>
                                        <a className="newsletter-social newsletter-social__instagram"
                                           href="https://www.instagram.com/dailyoverview/"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           key="SocialContainer3"
                                        >
                                            <img
                                                src={require("../images/icons/icon__instagram.svg")}
                                                width="24"
                                                alt="Daily Overview"
                                                className="grid-right"
                                            />
                                        </a>
                                        <a className="newsletter-social newsletter-social__amazon"
                                           href="https://www.amazon.com/Benjamin-Grant/e/B01HMX7AZU/"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           key="SocialContainer4"
                                        >
                                            <img
                                                src={require("../images/icons/icon__amazon.svg")}
                                                width="24"
                                                alt="Daily Overview"
                                                className="grid-right"
                                            />
                                        </a>
                                    </SocialContainer>
                                </div>
                            </div>
                        </NewsletterContainer>
                    </NewsletterWrapper>

                    <SectionGrey>
                        <Section small>
                            <ProjectColumn
                                col="1/4"
                                mobileCol="1/13"
                                className="margin-bottom--40"
                            >
                                <img
                                    src={require("../images/icons/icon__projects.svg")}
                                    alt="daily overview"
                                    className="image-icon__large"
                                />
                                <h2 className="section-title">{(pageData.projectsTitle) ? pageData.projectsTitle : 'Projects'}</h2>
                                <ProjectsParagraph>{(pageData.projectsLeadParagraph) ? pageData.projectsLeadParagraph : 'Explore select collaborations and projects that we’re proud of.'}</ProjectsParagraph>
                                <Link to={(pageData.projectsButtonLink) ? pageData.projectsButtonLink : '/collaborations'}>
                                  <button className="primary__button">
                                      <span>{(pageData.projectsButtonText) ? pageData.projectsButtonText : 'Projects'}</span>
                                      <img
                                          src={require("../images/icons/icon__chevron--white--right.svg")}
                                          alt="daily overview"
                                          className="image-icon"
                                      />
                                  </button>
                                </Link>
                            </ProjectColumn>
                            <ProjectColumnImages
                                col="4/13"
                                mobileCol="1/13"
                            >
                            <div className="product-row">
                            {pageData.productModules.map(block => (
                                <div className="product-block" key={block.id}>
                                    <Link to={block.url}>
                                        <div className="fourbythree">
                                            <Img
                                                fluid={block.image.fluid}
                                                className="product-img"
                                            />
                                        </div>
                                        <h3 className="body-copy product-text">
                                            {block.description}
                                        </h3>
                                    </Link>
                                </div>
                            ))}
                            </div>
                            </ProjectColumnImages>
                        </Section>
                    </SectionGrey>

                </div>
                <Footer />
            </Layout>
        );
    }
}

export default IndexPage;

export const query = graphql`
query HomeQuery {
  allDatoCmsHome {
    edges {
      node {
        introText
        circularImage {
          fluid(maxWidth: 900, imgixParams: {dpr: 1, w: "900", fit: "clip", auto: "compress"}) {
            ...GatsbyDatoCmsFluid
          }
        }
        featuredTitle
        featuredLeadParagraph
        featuredCategories {
          url
          name
        }
        shopSliderProducts {
          overview {
            id
            title
            slug
            image {
              fluid(maxWidth: 900, imgixParams: {dpr: 1, w: "900", fit: "clip", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
            shopLink
          }
        }
        shopSliderTitle
        shopSliderLeadParagraph
        shopSliderButtonText
        shopSliderButtonLink
        customPrintsImage {
          fluid(maxWidth: 900, imgixParams: {dpr: 1, w: "900", fit: "clip", auto: "compress"}) {
            ...GatsbyDatoCmsFluid
          }
        }
        customPrintsTitle
        customPrintParagraph
        bookImage {
          fluid(maxWidth: 900, imgixParams: {dpr: 1, w: "900", fit: "clip", auto: "compress"}) {
            ...GatsbyDatoCmsFluid
          }
        }
        bookTitle
        bookParagraph
        productModules {
          ... on DatoCmsContent {
            model {
              apiKey
            }
            url
            description
            image {
              fluid(maxWidth: 900, imgixParams: {dpr: 1, w: "900", fit: "clip", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
        ctaSection {
          emailPlaceholder
          emailSubmitButton
          newsletterInstructions
          sectionTitle
          socialLeadParagraph
          socialTitle
        }
        projectsTitle
        projectsLeadParagraph
        projectsButtonText
        projectsButtonLink
        heroSlider {
          ... on DatoCmsSlide {
            model {
              apiKey
            }
            color
            title
            buttonText
            buttonLink
            text
            crop
            image {
              fluid(maxWidth: 2000, imgixParams: {q: 85, dpr: 3, w: "2000", fit: "clip", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
            mobileImage {
              fluid(maxWidth: 2000, imgixParams: {q: 85, dpr: 3, w: "2000", fit: "clip", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
            overview {
              title
              description
              slug
              geo {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
  }
  allDatoCmsOverview(limit: 1) {
    edges {
      node {
        id
        originalId
        description
        title
        category {
          name
        }
        slug
        image {
          fluid(maxWidth: 2000, imgixParams: {q: 85, dpr: 3, w: "2000", fit: "clip", auto: "compress"}) {
            ...GatsbyDatoCmsFluid
          }
        }
        geo {
          latitude
          longitude
        }
      }
    }
  }
  allDatoCmsCategory(sort: {fields: position}) {
    edges {
      node {
        name
      }
    }
  }
}
`;
